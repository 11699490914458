import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';



export default function AlegereInterval(props) {

    return (

        <div>
            <br></br>
            <Typography variant="h6" gutterBottom>
                <center> Alegere interval</center>
            </Typography>
            <center>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => props.setInterval([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={props.interval}
                />
            </center>

        </div>

    );
}
